import React from "react"
import Header from "../Header/Header"
import FooterComponent from "../Footer/Footer"
import MetaLinks from "../MetaLinks/MetaLinks"
import { config } from "@fortawesome/fontawesome-svg-core"

import "@fortawesome/fontawesome-svg-core/styles.css"
import "../../styles/app.scss"
import "../../styles/header.scss"
import styles from "./layout.module.scss"

import { SkipNavLink, SkipNavContent } from "@reach/skip-nav"
import "@reach/skip-nav/styles.css"

config.autoAddCss = false

class Layout extends React.Component {
  render() {
    const { children } = this.props
    return (
      <>
        <MetaLinks />
        <SkipNavLink className="navbar-item navbar-skip-item footer-link" />
        <Header />
        <SkipNavContent />
        <div className={styles.siteMain}>{children}</div>
        <FooterComponent />
      </>
    )
  }
}

export default Layout
